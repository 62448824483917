import React, { useEffect } from "react";
import Legal from "../components/legal";
import Helmet from "react-helmet";
import bg from "../images/bg-shape.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/theme.scss";
import FooterNavigation from "../components/footerNavigation";
import SiteFooter from "../components/footer";

export default function InterstitialPage() {
  useEffect(() => {
    const wait = setInterval(() => {
      typeof window !== "undefined" &&
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      clear();
    }, 300);
    const clear = () => clearInterval(wait);
  }, []);
  return (
    <div className="  min-vh-100 d-flex flex-column">
      <Helmet>
        <script
          data-ad-client="ca-pub-1883784316422963"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        />
      </Helmet>
      <div style={{ width: "100%", flexGrow: 1 }}>
        <div className="container " style={{ width: "720px" }}>
          <div className="py-3">
            {" "}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-1883784316422963"
              data-ad-slot={5102685384}
              data-ad-format="link"
              data-full-width-responsive="true"
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2f2f2f" }}>
        <div className="container">
          <FooterNavigation interstitial />
        </div>
      </div>
    </div>
  );
}
